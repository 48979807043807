body {
  overflow:hidden;
  overflow-y:auto;
  
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (max-width: 900px) {
  .ant-card-meta-title {
    font-size: 15px !important;
  }
  .ant-card-meta-description {
    font-size: 15px !important;
  }
  .ant-card {
    width: 60% !important;
  }
}
@media only screen and (max-width: 767px) {
  #testimonials {
    display: none;
  }
  #testimonials_li {
    display: none !important;
  }

  .ant-card-meta-title {
    font-size: 15px !important;
  }
  .ant-card-meta-description {
    font-size: 15px !important;
  }
  .ant-card {
    width: 90% !important;
  }
}
/* Beautify the NewPortfolio images for mobile phone */
@media only screen and (max-width: 424px) {
  #newportfolio_ {
    /* width: 48px; */
  }
}
